$(".request_callback_button").on("click", function() {
    goToByScroll(".bottom_page_wrap", 0, 0.5);
});

$(".top_image_wrapper .find_out_more_button").on("click", function() {
    goToByScroll(".main_home_content_wrapper", 0, 0.5);
});

// Form validation
$(".gform_footer").on("click", function() {
    form_insurance_type = $('#field_15_1 select').val();
    form_full_name = $('#field_15_2 input[type="text"]').val();
    form_tel_no = $('#field_15_3 input[type="text"]').val();
    form_email = $('#field_15_4 input[type="text"]').val();

    if (form_insurance_type !== "" && form_full_name !== "" && form_tel_no !== "" && form_email !== "") {
        //console.log("Success - do nothing!");
    } else {
        sweetAlert("Please fill in all of the fields.", "Please ensure that all fields in the form below are filled out before you submit your request.", "error");
    }
}); 